var cursor = document.querySelector('.cursor');
document.addEventListener('mousemove', function(e) {
  //cursor.style.left = e.pageX = 'px';
  //cursor.style.top = e.pageY = 'px';
  cursor.style.left = e.clientX + 'px';
  cursor.style.top = e.clientY + 'px';
});

document.addEventListener("mouseover", function(e){
  const tag = e.target.tagName.toLowerCase();
  const classList = e.target.classList;

  if(
    tag === 'a' ||
    tag === 'button' ||
    tag === 'select' 
  ){
    cursor.classList.add("cursor-hover");
  } else {
    cursor.classList.remove("cursor-hover");
  }
});